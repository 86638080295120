import React from "react"
import { ArrowLeft, ArrowRight } from "react-feather"
import { Flex } from "theme-ui"

const CarouselNavigation = ({ id }) => (
  <Flex sx={{ alignItems: "center" }} dir="ltr">
    <CircleIconButton className={`prev-${id}`} mr={2}>
      <ArrowLeft />
    </CircleIconButton>
    <CircleIconButton className={`next-${id}`}>
      <ArrowRight />
    </CircleIconButton>
  </Flex>
)

const CircleIconButton = props => {
  return (
    <Flex
      {...props}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "full",
        width: ["40px", "50px"],
        minWidth: ["40px", "50px"],
        height: ["40px", "50px"],
        minHeight: ["40px", "50px"],
        backgroundColor: "dark",
        color: "light",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "primary",
        },
        "&.swiper-button-disabled": {
          opacity: 0.5,
          "&:hover": {
            cursor: "default",
            backgroundColor: "dark",
          },
        },
      }}
    />
  )
}

export default CarouselNavigation
