import React from "react"
import { Box, Container, Text, Flex } from "@theme-ui/components"
import ArticleThumb from "../components/articleThumb"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import { i18nContext } from "../context/i18nContext"
import { getArticlePath } from "../utils/path"
import CarouselNavigation from "./carouselNavigation"
SwiperCore.use([Mousewheel, Pagination, A11y, Navigation])
// End swiper

const LatestArticles = ({ articles }) => {
  const id = "latestNews"
  return (
    <Box sx={{ ".swiper-wrapper": { alignItems: "stretch" } }}>
      <Container>
        <Flex
          sx={{
            mb: [3, 3, 5],
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Text dir="invalid" variant="h2" mb={0} sx={{ color: "dark" }}>
            <i18nContext.Consumer>{t => t.latestArticles}</i18nContext.Consumer>
          </Text>
          <CarouselNavigation id={id} />
        </Flex>
        <Swiper
          spaceBetween={32}
          autoHeight={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            896: {
              slidesPerView: 4,
            },
          }}
          navigation={{
            nextEl: `.next-${id}`,
            prevEl: `.prev-${id}`,
          }}
          watchOverflow
        >
          {articles.map(article => (
            <SwiperSlide key={article.id}>
              <ArticleThumb article={article} getPath={getArticlePath} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Box>
  )
}

export default LatestArticles
