import React from "react"
import { Box } from "theme-ui"
import useMouse from "@react-hook/mouse-position"
import DotRing from "./cursor"

const CursorWrapper = ({ children, customCursor }) => {
  const ref = React.useRef(null)
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  })

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        // pointerEvents: "none",
        transform: ["none", "translateX(-50%)", "none"],
      }}
      ref={ref}
    >
      <DotRing x={mouse.x} y={mouse.y} />
      {children}
    </Box>
  )
}

export default CursorWrapper
