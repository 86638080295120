import React from "react"
import { Box, Text, Flex } from "theme-ui"

const DotRing = ({ x, y }) => {
  // 1.
  return (
    <Box sx={{ position: "relative" }}>
      {/* 2. */}
      <Flex
        sx={{
          //   left: `700px`,
          //   top: `600px`,
          left: `${x}px`,
          top: `${y}px`,
          position: "absolute",
          transform: "translate(-20%, -50%)",
          transitionDuration: "100ms",
          transitionTimingFunction: "ease-out",
          willChange: "transform",
          zIndex: 999,
          pointerEvents: "none",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid",
            borderColor: "light",
            height: "60px",
            minHeight: "60px",
            width: "60px",
            minWidth: "60px",
            borderRadius: "full",
          }}
        ></Box>
        <Text
          sx={{
            color: "primary",
            fontSize: "11px",
            textTransform: "uppercase",
            m: 0,
            ml: 2,
          }}
        >
          Click to enter
          <br />
          our world
        </Text>
      </Flex>
    </Box>
  )
}

export default DotRing
