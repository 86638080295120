import React, { useEffect, useState } from "react"
import { AnimatePresence } from "framer-motion"
import { Box, Container, Flex, Heading, Text, Grid, Image } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import AnimatedBox from "../utils/motion"
import { ArrowLeft, ArrowRight, ChevronDown, Play, X } from "react-feather"
import linkSwitch from "../utils/linkSwitch"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui/index"
import { ArrowUpRight } from "react-feather"
import betterWay from "../images/better-way.jpg"
import Transition from "./transition"
import ReactModal from "react-modal"
import { useBreakpointIndex } from "@theme-ui/match-media"
import CursorWrapper from "./cursorWrapper"

const HomeSlider = ({ slides, way }) => {
  const [isBrowser, setIsBrowser] = useState(typeof window !== `undefined`)
  const [open, setOpen] = useState(false)
  const [customCursor, setCustomCursor] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [showTheWay, setShowTheWay] = useState(false)
  const index = useBreakpointIndex()
  useEffect(() => {
    setIsBrowser(typeof window !== `undefined`)
  }, [])
  // const mouse = useMouse(ref, {
  //   enterDelay: 100,
  //   leaveDelay: 100,
  // })

  ReactModal.defaultStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      width: index === 0 ? "90vw" : "70vw",
      padding: "0 !important",
      background: "#000",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      maxHeight: "80vh",
    },
  }

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: ["30rem", "40rem", "40rem", "50rem"],
        height: ["100vh", "100vh", "100vh", "100vh"],
        maxHeight: ["42rem", "50rem", "100vh", "100vh"],
        overflow: "hidden",
        backgroundColor: "dark",
        mb: [0, 0, 7],
      }}
    >
      <Box>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
        >
          {isBrowser && (
            <CursorWrapper customCursor={customCursor}>
              <Transition
                slides={slides}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
              />
            </CursorWrapper>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            backgroundColor: "dark",
            opacity: 0.4,
            pointerEvents: "none",
          }}
        ></Box>

        {slides.map((slide, index) => (
          <AnimatePresence>
            {index === currentSlide && (
              <>
                {/* TITLE */}
                <AnimatedBox
                  initial={{ opacity: 0, y: 40, skewX: 10 }}
                  animate={{ opacity: 1, y: 0, skewX: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                    delay: 0.3,
                  }}
                  sx={{
                    position: "absolute",
                    bottom: [6, 6],
                    padding: [4, 4, 5, 6],
                    width: ["100%", "80%", "80%", "60%"],
                    maxWidth: ["50rem", "50rem", "40rem", "42rem"],
                    pointerEvents: "none",
                  }}
                >
                  <Box
                    sx={{
                      lineHeight: [1.1, 1],
                      fontSize: ["28px", 5, 6, 6],
                      color: "primary",
                      mb: 3,
                      p: {
                        lineHeight: [1.1, 1],
                        fontSize: ["28px", 5, 6, 6],
                        color: "primary",
                        mb: 3,
                      },
                    }}
                  >
                    <Box dangerouslySetInnerHTML={{ __html: slide.title }} />
                  </Box>
                  <Text
                    dir="invalid"
                    as="h2"
                    variant="h2"
                    color="light"
                    sx={{
                      fontSize: [2, 4, 4, 4],
                      lineHeight: [1.3, 1.5],
                      mt: 0,
                      width: ["100%", "100%"],
                    }}
                  >
                    {slide.subtitle}
                  </Text>
                  {index === 0 && (
                    <Flex
                      sx={{
                        alignItems: "center",
                        mt: [3, 4, 4, 5],
                        color: "primary",
                        fontSize: [2, 3],
                        pointerEvents: "auto",
                      }}
                      onClick={e => {
                        setShowTheWay(true)
                        setCurrentSlide(0)
                      }}
                    >
                      <Flex
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "full",
                          width: "50px",
                          height: "50px",
                          minWidth: "50px",
                          minHeight: "50px",
                          mr: 3,
                          backgroundColor: "primary",
                        }}
                      >
                        <ArrowUpRight color={getColor(themeUiTheme, "light")} />
                      </Flex>
                      {way.externalCallToAction}
                    </Flex>
                  )}
                  {slide.link && (
                    <Box
                      sx={{
                        alignItems: "center",
                        mt: [3, 4, 4, 5],
                        a: { color: "light" },
                        fontSize: [2, 3],
                        pointerEvents: "auto",
                        display: "inline-flex",
                      }}
                      onMouseEnter={() => setCustomCursor(false)}
                      onMouseLeave={() => setCustomCursor(true)}
                    >
                      <Flex
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "full",
                          width: "50px",
                          height: "50px",
                          minWidth: "50px",
                          minHeight: "50px",
                          mr: 3,
                          backgroundColor: "light",
                        }}
                      >
                        <ArrowUpRight color={getColor(themeUiTheme, "dark")} />
                      </Flex>
                      {linkSwitch(slide.link, slide.locale)}
                    </Box>
                  )}
                </AnimatedBox>
                {slide.productImage && (
                  <AnimatedBox
                    initial={{
                      opacity: 0,
                      x: 200,
                    }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.4,
                        type: "spring",
                        damping: 10,
                        mass: 0.75,
                        stiffness: 100,
                        delay: 1,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      x: 500,
                      transition: {
                        duration: 0.4,
                        type: "spring",
                        damping: 10,
                        mass: 0.75,
                        stiffness: 100,
                      },
                    }}
                    sx={{
                      position: "absolute",
                      display: ["none", "none", "block"],
                      bottom: "15%",
                      right: 6,
                      "& > .gatsby-image-wrapper": {
                        height: "10%",
                        display: "block",
                      },
                      pointerEvents: "none",
                    }}
                  >
                    <GatsbyImage
                      image={slide.productImage.gatsbyImageData}
                    ></GatsbyImage>
                  </AnimatedBox>
                )}
              </>
            )}
          </AnimatePresence>
        ))}
        {/* SCROLL */}
        <Flex
          sx={{
            position: "absolute",
            bottom: [2, 3, 3, 3],
            left: 0,
            color: "lightBackground",
            padding: 0,
            textTransform: "uppercase",
            fontSize: [0],
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Flex sx={{ alignItems: "center" }}>
            <Text dir="invalid" sx={{ mr: 2 }}>Scroll Down</Text>
            <ChevronDown />
          </Flex>
        </Flex>
        {/* ARROW */}
        <Flex
          sx={{
            position: "absolute",
            top: ["9rem", "12rem", "14rem", "25%"],
            right: [4, 4, 6, 6],
            color: "light",
            padding: 0,
            textTransform: "uppercase",
            fontSize: 0,
            width: ["60px"],
            height: ["60px"],
            border: "2px solid",
            borderColor: "secondary",
            borderRadius: "full",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
          }}
        >
          <ArrowRight />
        </Flex>
        {/* DOTS */}
        <Box
          sx={{
            position: "absolute",
            bottom: [4, 5],
            right: 0,
            width: "100%",
          }}
        >
          <Flex
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingX: [4, 5, 6, 9],
              paddingY: 3,
              background:
                "linear-gradient(180deg, rgba(0,0,0,0) calc(50% - 1px), rgba(255,255,255,0.3) calc(50%), rgba(0,0,0,0) calc(50% + 1px))",
            }}
          >
            {slides.map((slide, index) => (
              <AnimatedBox
                initial={{ opacity: 0, x: -400 * index }}
                animate={{ opacity: 1, x: 0, transition: { delay: 1 } }}
                onClick={() => setCurrentSlide(index)}
                sx={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "full",
                  backgroundColor:
                    index === currentSlide ? "primary" : "#ffffff90",
                }}
              ></AnimatedBox>
            ))}
          </Flex>
        </Box>
        {/* SHOW ME THE WAY */}
        <AnimatePresence>
          {showTheWay && (
            <AnimatedBox
              initial={{
                opacity: 0,
                scale: 5,
                y: 300,
                transition: { duration: 0.3 },
              }}
              animate={{
                opacity: 1,
                scale: 1,
                y: 0,
                transition: { duration: 0.3 },
              }}
              exit={{
                opacity: 0,
                scale: 5,
                y: 300,
                transition: { duration: 0.3 },
              }}
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                padding: 0,
                top: 0,
                left: 0,
                backgroundColor: "dark",
                color: "light",
              }}
            >
              <Box sx={{ marginTop: ["7rem", "8rem", "8rem", "12rem"] }}>
                <Flex
                  sx={{
                    justifyContent: "flex-start",
                    paddingTop: [3, 4],
                    paddingBottom: [5, 4, 4, 4],
                    paddingX: [3, 4, 3, 4],
                  }}
                >
                  <Flex
                    sx={{
                      position: "relative",
                      left: [0, 0, 0, 2],
                      color: "light",
                      padding: 0,
                      textTransform: "uppercase",
                      fontSize: 0,
                      width: ["60px"],
                      height: ["60px"],
                      border: "2px solid",
                      borderColor: "secondary",
                      borderRadius: "full",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowTheWay(false)
                      setCurrentSlide(0)
                    }}
                  >
                    <ArrowLeft />
                  </Flex>
                </Flex>
                <Grid columns={[0, 0, "2fr 3fr"]}>
                  <Box
                    sx={{
                      textAlign: "right",
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      width: ["60%", "60%", "40%", "40%"],
                      maxHeight: ["40rem", "36rem", "40rem", "40rem"],
                    }}
                  >
                    <Image src={betterWay} />
                  </Box>
                  <Container sx={{ paddingTop: 0 }}>
                    <Box
                      sx={{
                        position: "relative",
                        color: "secondary",
                        fontSize: ["60px", "100px", "140px", "200px"],
                        lineHeight: "0.8",
                      }}
                    >
                      ABetter
                      <br />
                      Way
                    </Box>
                    <Box
                      sx={{
                        position: "relative",
                        width: "20rem",
                        mt: [4, 4, 0, 0],
                        top: [0, 0, -5, -6],
                        left: [0, 0, "20rem", "30rem"],
                      }}
                    >
                      <Box dangerouslySetInnerHTML={{ __html: way.text }} />
                      <Flex
                        sx={{
                          alignItems: "center",
                          mt: 4,
                          color: "primary",
                          fontSize: 3,
                        }}
                        onClick={() => {
                          setOpen(true)
                        }}
                      >
                        <Flex
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "full",
                            width: "50px",
                            height: "50px",
                            minWidth: "50px",
                            minHeight: "50px",
                            mr: 3,
                            backgroundColor: "primary",
                          }}
                        >
                          <Play color={getColor(themeUiTheme, "light")} />
                        </Flex>
                        {/* Open Modal */}
                        <Box>{way.callToAction}</Box>
                      </Flex>
                    </Box>
                  </Container>
                </Grid>
              </Box>
            </AnimatedBox>
          )}
        </AnimatePresence>
      </Box>
      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "0px",
            paddingBottom: "56.25%",
            backgroundColor: "darkBackground",
            iframe: {
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
            },
          }}
          dangerouslySetInnerHTML={{ __html: way.video }}
        />
      </ReactModal>
    </Box>
  )
}

export default HomeSlider
