import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Text, Container } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import CarouselNavigation from "../carouselNavigation"
import { Flex } from "theme-ui"
SwiperCore.use([Mousewheel, Navigation, A11y])
// End swiper

const ImageGallery = ({ images, fullWidth, id }) => {
  const Wrapper = fullWidth ? Box : Container
  return (
    <Wrapper
      sx={{
        position: "relative",
        backgroundColor: fullWidth ? "#efefef" : "ffffff",
      }}
    >
      <Swiper
        spaceBetween={fullWidth ? 0 : 16}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          896: {
            slidesPerView: fullWidth ? 1 : 2,
          },
        }}
        navigation={{
          nextEl: `.next-${id}`,
          prevEl: `.prev-${id}`,
        }}
        autoHeight
        watchOverflow
      >
        {images.map(image => (
          <SwiperSlide key={image.originalId}>
            <StyledImage image={image} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{ position: "absolute", bottom: [3, 6], right: [3, 5], zIndex: 1 }}
      >
        <CarouselNavigation id={id} />
      </Box>
    </Wrapper>
  )
}

const StyledImage = ({ image }) => (
  <>
    <Flex
      sx={{
        ".gatsby-image-wrapper": {
          margin: "0 auto",
        },
      }}
    >
      <GatsbyImage image={image.gatsbyImageData} alt="" />
    </Flex>
    {image.title && (
      <Box sx={{ px: 4, mt: 2 }}>
        <Text dir="invalid" variant="caption">
          {image.title}
        </Text>
      </Box>
    )}
  </>
)

export default ImageGallery
